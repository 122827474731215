.oac-paging {
    width: 100%;
    text-align: right;
    margin-top: 15px;
}

.oac-paging img.small-icon {
    width: 20px;
    margin: 0 10px;
    vertical-align: top;
    cursor: pointer;
}

.oac-paging span {
    /*margin-top: 5px;*/
    display: inline-block;
}

.oac-paging.ja {
    /*margin-left: -50px;*/
}