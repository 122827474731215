.care-center-management {
    font-size: 20px;
}

.care-center-management .data {
    padding: 5px 0;
    position: relative;
}

.care-center-management .tabs {
    margin-right: 20px;
    margin-bottom: 20px;
}

.care-center-management .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 36px;
    left: 0;
}

.care-center-management .tabs a {
    text-align: center;
    padding: 0 20px;
    display: inline-block;
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.care-center-management .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.care-center-management .tabs a:hover {
    text-decoration: none;
}

.care-center-management .tabs a.selected {
    font-weight: bold;
    background-color: var(--main-color);
    color: white !important;
}

.care-center-management .view-cc {
    width: 1576px;
    margin-top: 10px;
}

.care-center-management .view-cc .esp-table-header td{
    height: 75px;
    word-break: break-word;
}

.care-center-management .view-cc td.max {
    width: 1575px !important;
}

.care-center-management .view-cc td:nth-child(1) {
    width: 164px;
}

.care-center-management .view-cc td:nth-child(2) {
    width: 191px;
}

.care-center-management .view-cc td:nth-child(3) {
    width: 191px;
}

.care-center-management .view-cc td:nth-child(4) {
    width: 192px;
}

.care-center-management .view-cc td:nth-child(5) {
    width: 165px;
}

.care-center-management .view-cc td:nth-child(6) {
    width: 212px;
}

.care-center-management .view-cc td:nth-child(7) {
    width: 212px;
}

.care-center-management .view-cc td:nth-child(8) {
    width: 97px;
}

.care-center-management .view-cc td:nth-child(9) {
    width: 150px;
}

.care-center-management .ml145 {
    margin-left: 145px;
}

.care-center-management .w50px {
    width: 50px;
}

.care-center-management .w325px {
    width: 325px;
}

.care-center-management .w223px {
    width: 323px;
    text-align: right;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
}

.care-center-management .w160px {
    width: 160px;
    text-align: right;
    margin-right: 30px;
    display: inline-block;
    vertical-align: top;
}

.care-center-management input {
    border-radius: 5px;
    height: 35px;
    padding: 0 0 0 5px;
}

.care-center-management .table-descr {
    display: flex;
}

.care-center-management .table-descr .inline {
    width: 33%;
}

.care-center-management .table-descr a {
    text-decoration: underline;
}

.care-center-management textarea {
    height: 70px;
    padding: 0 0 0 5px;
}

.care-center-management textarea.placeholder-right::-webkit-input-placeholder,
.care-center-management input.placeholder-right::-webkit-input-placeholder {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.care-center-management textarea.placeholder-right::-ms-input-placeholder,
.care-center-management input.placeholder-right::-ms-input-placeholder {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.care-center-management textarea.placeholder-right::placeholder,
.care-center-management input.placeholder-right::placeholder {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.care-center-management textarea.lg,
.care-center-management input.lg {
    width: 490px;
    border-radius: 0;
}

.care-center-management .mt10 {
    margin-top: 10px;
}

.care-center-management .mt5 {
    margin-top: 5px;
}

.care-center-management .mt20 {
    margin-top: 20px;
}

.care-center-management .mt50 {
    margin-top: 50px;
}

.care-center-management div.mt5 {
    max-height: 70px;
}

.care-center-management input[type=checkbox] {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.care-center-management label {
    display: inline-block;
    vertical-align: bottom;
    height: 25px;
}

.care-center-management .add-csp-modal .modal-content {
    width: 900px;
    /*height: 845px;*/
    font-size: 20px;
}

@media only screen and (max-device-width: 500px) {
    .care-center-management .add-csp-modal .modal-content {
        font-size: 16px !important;
    }
}

.care-center-management .delete-cc-modal .modal-content {
    width: 750px;
    height: 600px;
    font-size: 20px;
}

@media only screen and (max-device-width: 500px) {
    .care-center-management .delete-cc-modal .modal-content {
        font-size: 16px !important;
    }
}

.care-center-management .add-csp-modal .modal-dialog {
    margin: 4% auto;
    max-width: 900px;
}

.care-center-management .delete-cc-modal .modal-dialog {
    margin: 10% 34%;
}

.care-center-management .add-csp-modal .modal-body {
    padding: 10px 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    min-height: 550px;
}

.care-center-management .link {
    text-decoration: underline !important;;
    color: #1464f6 !important;
    cursor: pointer;
}

.care-center-management .m35 {
    margin-top: 15px;
}

.care-center-management .inline.w50p {
    width: 50%;
}

.care-center-management .esp-select {
    width: 490px;
    height: 35px;
}

.care-center-management .delete-cc-modal div.inline {
    max-width: 490px;
}

.care-center-management .esp-table-header td,
.care-center-management .esp-table-body td {
    text-align: left;
    padding-left: 10px !important;
}

.table-descr input.w50px {
    text-align: center;
}


.care-center-management .modal-content .esp-select__control {
    border-radius: 0;
    border: 1px solid #808080 !important
}

.care-center-management .modal-content .esp-select__indicators {
    background: transparent;
    border: none !important;
    margin-top: 2px;
}

.care-center-management .modal-content .esp-select__dropdown-indicator {
    color: black !important;
    background-color: black !important;
    width: 1em !important;
    height: 0.8em !important;
}

.care-center-management .table-descr .link img {
    width: 40px;
    margin-right: 5px;
}

.care-center-management .l1315 {
    left: 1380px;
}

.care-center-management .top-10 {
    top: 10px !important;;
}

.care-center-management .bottom-10 {
    bottom: -10px !important;;
}

.care-center-management label.label-holder {
    font-size: 16px;
    color: #b8b9b2;
    font-style: italic;
    position: absolute;
    right: 10px;
    bottom: 0;
}

.care-center-management .modal-body div.inline {
    position: relative;
}

.care-center-management input:read-only {
    color: #b8b9b2;
}

.care-center-management div.error {
    padding: 10px 40px;
    border: 1px dashed;
}

.care-center-management .ml-600 {
    margin-left: -600px !important
}

.care-center-management .oac-paging span {
    margin-top: 0;
}

.link.disabled {
    color: #666666 !important;
}