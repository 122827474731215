.scheduling .table-descr {
    display: flex;
}

.scheduling .table-descr .inline {
    width: 33%;
}

.scheduling .table-descr a {
    text-decoration: underline;
}

.scheduling .result {
    margin-top: 10px;
    width: 1538px;
}

.scheduling .result .esp-table-header td{
    height: 75px;
    word-break: break-word;
    text-align: center;
}

.scheduling .result .esp-table-body td{
    word-break: break-word;
    text-align: center;
    height: 35px;
}

.scheduling .result td.max {
    width: 1537px !important;
}

.scheduling .result td:nth-child(1) {
    width: 370px;
    text-align: left;
    padding-left: 10px !important;
}

.scheduling .result td:nth-child(2) {
    width: 158px;
    text-align: left;
    padding-left: 10px !important;
}

.scheduling .result td:nth-child(3) {
    width: 154px;
    text-align: left;
    padding-left: 10px !important;
}

.scheduling .result td:nth-child(4) {
    width: 95px;
}

.scheduling .result td:nth-child(5) {
    width: 125px;
}

.scheduling .result td:nth-child(6) {
    width: 105px;
}

.scheduling .result td:nth-child(7) {
    width: 135px;
}

.scheduling .result td:nth-child(8) {
    width: 174px;
    padding: 15px !important;
}

.scheduling .result td:nth-child(9) {
    width: 110px;
}

.scheduling .result td:nth-child(10) {
    width: 110px;
}


.scheduling .w50px {
    width: 50px;
}

.scheduling .w325px {
    width: 325px;
}

.scheduling .mt50 {
    margin-top: 50px;
}

.scheduling .inline.w50p {
    width: 50%;
}

.scheduling input {
    border-radius: 5px;
    height: 35px;
}

.scheduling .data {
    padding: 5px 0;
    position: relative;
}

.scheduling .tabs {
    margin-right: 20px;
    margin-bottom: 20px;
}

.scheduling .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 36px;
    left: 0;
}

.scheduling .tabs a {
    text-align: center;
    padding: 0 20px;
    display: inline-block;
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.scheduling .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.scheduling .tabs a:hover {
    text-decoration: none;
}

.scheduling .tabs a.selected {
    font-weight: bold;
    background-color: var(--main-color);
    color: white !important;
}

.scheduling a.link {
    text-decoration: underline;
    cursor: pointer;
}

.scheduling .proceed-schedule-modal {

}

.scheduling .proceed-schedule-modal .modal-content {
    width: 460px;
    min-height: 390px;
}

.scheduling .proceed-schedule-modal .modal-content .inline.w160px {
    padding: 10px 10px 10px 0;
}

.scheduling .schedule-picker input {
    width: 260px !important;
    padding: 5px;
}

.scheduling .schedule-picker .rc-time-picker,
.scheduling .schedule-picker .rc-time-picker-panel-input,
.scheduling .schedule-picker .rc-time-picker input {
    width: 100px !important;
}

.scheduling .rc-time-picker-input,
.scheduling .rc-time-picker-panel-select li {
    font-size: 20px;
}

.scheduling .l1120 {
    left: 1360px
}

.scheduling .oac-paging span {
    margin-top: 0;
    min-width: 180px;
}

.scheduling .oac-paging {
    width: 1548px;
}