.logout-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
    z-index: 2;
}

.logout-modal.esp-modal .modal-content {
    width: 350px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.logout-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.logout-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: center;
}

.logout-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.logout-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
    z-index: 2;
}

.already-open-modal.esp-modal .modal-content {
    width: 360px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.already-open-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: left;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.already-open-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 5px;
}

.retry-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
}

.retry-modal.esp-modal .modal-content {
    width: 293px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.retry-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.retry-modal.esp-modal .modal-header .modal-title {
    height: 47px;
    padding: 5px;
    color: #E61610;
}

.retry-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: center;
    padding: 10px 5px;
    color: #E61610;
}

.retry-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.retry-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 0 0 5px 0;
}

.about-modal.iresp-modal {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.about-modal.iresp-modal .modal-content {
    font-size: 16px;
    min-height: 183px !important;
    box-shadow: 5px 5px 10px #888888;
    border: 2px solid #888;
    width: 370px;
}

.about-modal.iresp-modal .modal-body {
    /*min-height: 183px;*/
    text-align: left;
}

.about-modal.iresp-modal .modal-footer {
    padding: 0;
    min-height: 55px;
    height: 55px;
    font-size: 20px;
}

.about-modal.iresp-modal .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 10px;
}

.about-modal.iresp-modal .about-versions {
    margin: 25px 0 0 10px;
}

.about-modal.iresp-modal p {
    margin: 0;
}

.about-modal.iresp-modal .modal-content .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.about-modal-4P.iresp-modal {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.about-modal-4P.iresp-modal .modal-dialog {
    margin: 14% 38%;
}

.about-modal-4P.iresp-modal .modal-content {
    font-size: 16px;
    min-height: 313px !important;
    box-shadow: 5px 5px 10px #888888;
    border: 2px solid #888;
    width: 550px;
}

.about-modal-4P.iresp-modal .modal-body {
    min-height: 230px;
    text-align: left;
}

.about-modal-4P.iresp-modal .modal-footer {
    padding: 0;
    min-height: 55px;
    height: 55px;
    font-size: 20px;
}

.about-modal-4P.iresp-modal .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 10px;
}

.about-modal-4P.iresp-modal .about-versions {
    margin: 25px 0 0 10px;
}

.about-modal-4P.iresp-modal p {
    margin: 0;
}

.about-modal-4P.iresp-modal .modal-content .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.about-modal-4P.iresp-modal .modal-content .inline.w-50 {
    padding: 0;
}