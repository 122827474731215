html, body {
    font-size: 14px;
    overflow: var(--overflow);
}

input[type=checkbox] {
    border: 2px solid #2196F3 !important;
    border-radius: 2px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input[type=checkbox].disabled {
    border: 2px solid grey !important;
}

input[type=checkbox]:checked {
    -moz-appearance: checkbox;
    -webkit-appearance: checkbox;
}

input::placeholder {
    font-style: italic !important;
    font-family: var(--main-font), sans-serif;
    font-size: inherit;
    color: #AAAAAA;
}

td.max {
    font-style: italic;
    text-align: center !important;
}

a.disabled {
    color: grey !important;
    pointer-events: none;
}

.error {
    color: #e61610;
    min-height: 44px;
    max-width: 100% !important;
}

.error div {
    border: 1px dashed;
    min-width: 350px;
    padding: 0 5px;
}

.success {
    height: auto;
    font-style: italic;
    font-size: 20px;
}

.flashing {
    color: red;
    animation-name: flashing;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.border-red {
    border: 1px solid red !important;
}

.abnormalEl {
    color: #000000;
    background-color: #F4AD9C;
}

.abnormalElS2 {
    color: #DB3B26;
}

.color-red {
    /*color: #DB3B26 !important;*/
    color: red !important;
}

.note-red {
    color: #ff3823 !important;
    font-weight: bold;
}

.color-grey {
    color: grey;
}

.lh15 {
    line-height: 1.5;
}

.underline {
    text-decoration: underline;
}

.descr {
    opacity: 0.4;
    font-style: italic;
    margin-bottom: 10px;
}

.w19p {
    width: 385px !important;
}

.w780 {
    width: 780px !important;
}

@keyframes flashing {
    from {
        opacity: 1.0
    }
    to {
        opacity: 0.4
    }
}

.pointer {
    cursor: pointer;
}

.container-fluid {
    overflow: hidden;
}

.inline {
    display: inline-block;
}

.zoom {
    /*zoom: var(--main-zoom);*/
}

.zoom-2 {
    /*zoom: 1.0;*/
}

.zoom-3 {
    /*zoom: 1.0;*/
}

.bg-error {
    background-color: rgb(244, 173, 156);
}

:root {
    --main-color: rgb(41, 171, 224);
    --main-body-width: calc(100% - 60px);
    --main-body-margin: 25px;
    --main-zoom: 1.0;
    --tile-height: 151px;
    --edit-index: 1;
    --overflow: hidden;
    --main-font: "Noto";
}

/*/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #242424 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
    position: absolute;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 15px;
}