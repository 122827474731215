.diagnostics .table-descr {
    display: flex;
}

.diagnostics .table-descr .inline {
    width: 33%;
    line-height: 1.0;
}

.diagnostics .table-descr a {
    text-decoration: underline;
}

.diagnostics .result {
    margin-top: 10px;
    width: 1275px;
}

.diagnostics .result-paging {
    width: 1285px;
}

.diagnostics .result .esp-table-body {
    max-height: 395px;
}

.diagnostics .result .esp-table-header td {
    height: 75px;
    word-break: break-word;
    /*text-align: left;*/
    /*padding-left: 10px !important;*/
}

.diagnostics .result td.max {
    width: 1274px !important;
}

.diagnostics .result td {
    /*text-align: left;*/
    /*padding-left: 10px !important;*/
}

.diagnostics .result td:nth-child(1) {
    width: 220px;
}

.diagnostics .result td:nth-child(2) {
    width: 190px;
}

.diagnostics .result td:nth-child(3) {
    width: 130px;
}

.diagnostics .result td:nth-child(4) {
    width: 373px;
}

.diagnostics .result td:nth-child(5) {
    width: 170px;
}

.diagnostics .result td:nth-child(6) {
    width: 95px;
}

.diagnostics .result td:nth-child(7) {
    width: 96px;
}

.diagnostics .w50px {
    width: 50px;
}

.diagnostics .w325px {
    width: 325px;
}

.diagnostics .mt50 {
    margin-top: 50px;
}

.diagnostics .inline.w50p {
    width: 50%;
}

.diagnostics input {
    border-radius: 5px;
    height: 35px;
}

.diagnostics .esp-select__placeholder {
    top: 44%;
}

.diagnostics .esp-select__single-value {
    top: 44%;
}

.diagnostics .data {
    padding: 5px 0;
    position: relative;
}

.diagnostics .tabs {
    margin-right: 20px;
    margin-bottom: 20px;
}

.diagnostics .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 36px;
    left: 0;
}

.diagnostics .tabs a {
    text-align: center;
    padding: 0 20px;
    display: inline-block;
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.diagnostics .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.diagnostics .tabs a:hover {
    text-decoration: none;
}

.diagnostics .tabs a.selected {
    font-weight: bold;
    background-color: var(--main-color);
    color: white !important;
}

.diagnostics .ml90 {
    margin-left: 90px;
}

.diagnostics .overview {
    margin-top: 10px;
    width: 741px;
}

.diagnostics .overview-paging {
    width: 751px;
}

.diagnostics .overview .esp-table-header td {
    height: 75px;
    word-break: break-word;
    text-align: left;
    padding-left: 10px !important;
}

.diagnostics .overview td.max {
    width: 740px !important;
}

.diagnostics .overview td {
    text-align: left;
    padding-left: 10px !important;
}

.diagnostics .overview td:nth-child(1) {
    width: 170px;
}

.diagnostics .overview td:nth-child(2) {
    width: 450px;
}

.diagnostics .overview td:nth-child(3) {
    width: 120px;
    text-align: center;
}

.diagnostics .installation-list {
    margin-top: 10px;
    width: 760px;
}

.diagnostics .installation-paging {
    width: 770px;
}

.diagnostics .installation-list .esp-table-header td {
    height: 50px;
    word-break: break-word;
    /*text-align: center;*/
    /*padding-left: 10px !important;*/
}

.diagnostics .installation-list td.max {
    width: 759px !important;
}

.diagnostics .installation-list td {
    /*text-align: center;*/
    /*padding-left: 10px !important;*/
}

.diagnostics .installation-list td.w1 {
    width: 130px;
}

.diagnostics .installation-list td.w2 {
    width: 180px;
}

.diagnostics .installation-list td.w3 {
    width: 360px;
}

.diagnostics .installation-change {
    margin-top: 10px;
    width: 471px;
}

.diagnostics .installation-change .esp-table-header td {
    height: 75px;
    word-break: break-word;
    /*padding-left: 10px !important;*/
}

.diagnostics .installation-change td.max {
    width: 471px !important;
}

.diagnostics .installation-change td {
    /*padding-left: 10px !important;*/
}

.diagnostics .installation-change td:nth-child(1) {
    width: 100px;
}

.diagnostics .installation-change td:nth-child(2) {
    width: 110px;
}

.diagnostics .installation-change td:nth-child(3) {
    width: 120px;
}

.diagnostics .installation-change td:nth-child(4) {
    width: 140px;
    padding: 0 7px !important;
}

.diagnostics .warranty {
    margin-top: 10px;
    width: 1076px;
}

.diagnostics .warranty-paging {
    width: 1086px;
}

.diagnostics .warranty .esp-table-header td {
    word-break: break-word;
    padding: 10px !important;
}

.diagnostics .warranty .esp-table-body {
    max-height: 424px !important;
}

.diagnostics .warranty .esp-table-body td {
    height: 50px;
}

.diagnostics .warranty td.max {
    width: 1075px !important;
}

.diagnostics .warranty td.w1 {
    width: 111px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics .warranty td.w12 {
    width: 107px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics .warranty td.w12 .sort-icon-wrapper {
    margin-top: -15px !important;
}

.diagnostics .warranty td.w2 {
    width: 110px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics .warranty td.w22 {
    width: 120px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics .warranty td.w3 {
    width: 140px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics .warranty td.w4 {
    /*width: 502px;*/
}

.diagnostics .warranty td.w5 {
    /*width: 397px;*/
}

.diagnostics .warranty td.w6 {
    width: 117px;
    text-align: left;
    padding-left: 5px !important;
}

.diagnostics td span.link {
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
}

.diagnostics .v-top {
    vertical-align: top;
    margin-top: 20px;
    float: right;
}

.diagnostics .l260 {
    left: 565px;
}

.diagnostics .l580 {
    left: 795px;
}

.diagnostics .l590 {
    left: 590px;
}

.diagnostics .l925 {
    left: 900px;
}

.diagnostics .inline.right {
    width: 66% !important;
    text-align: right;
}

.diagnostics .mt5 {
    margin-top: 5px;
}