.filter-search-combine {
    margin-bottom: 50px
}

.filter-search-combine input {
    width: 345px;
    height: 35px;
    margin: 0;
    padding: 0 30px 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filter-search-combine .fsc-control {
    position: relative;
    margin: 0 30px;
    width: 345px;
}

.filter-search-combine .fsc-indicator {
    color: black !important;
    background-color: black !important;
    width: 1em !important;
    height: 0.8em !important;
    margin-top: 2px;
    padding: 3px 4px !important;
    border-radius: 0 3px 3px 0;
    content: "";
    clip-path: polygon(50% 79%, 0 0, 100% 0);
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    position: absolute;
    top: 40px;
    right: 10px;
    cursor: pointer;
}

.filter-search-combine .fsc-menu {
    position: absolute;
    z-index: 1;
    top: 66px;
    left: 0;
    width: 450px;
    border-radius: 4px;
    background: white;
}

.filter-search-combine .fsc-menu.show {
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
}

.filter-search-combine .fsc-option:hover {
    background-color: #B2D4FF;
}

.filter-search-combine .fsc-option {
    border-bottom: 1px solid rgb(192, 192, 192);
    min-height: 35px;
    padding: 1px 10px;
}

.filter-search-combine img {
    cursor: pointer;
}