.calendar {
    position: relative;
}

.calendar .react-calendar {
    display: block;
    position: absolute;
    background-color: white;
    margin: -65px auto 0;
    z-index: 2;
    font-family: var(--main-font), sans-serif !important;
}

.calendar .abbr[data-original-title], abbr[title] {
    text-decoration: underline !important;
    cursor: default !important;
    border-bottom: 0;
}

.calendar .react-calendar__tile.react-calendar__month-view__days__day {
    background-color: white;
    opacity: 1;
    z-index: 2;
}

.calendar .react-calendar__tile--active.react-calendar__month-view__days__day {
    color: white;
    background-color: var(--main-color);
}

.calendar .react-calendar__tile.react-calendar__year-view__months__month {
    z-index: 2;
    background-color: white;
}

.calendar .react-calendar__tile.react-calendar__year-view__months__month:hover {
    color: white;
    background-color: var(--main-color);
}

.calendar .react-calendar__tile.react-calendar__decade-view__years__year {
    z-index: 2;
    background-color: white;
}

.calendar .react-calendar__tile.react-calendar__decade-view__years__year:hover {
    color: white;
    background-color: var(--main-color);
}

.calendar .react-calendar__month-view__weekdays__weekday {
    color: black;
}

.calendar .react-calendar__tile:disabled.react-calendar__month-view__days__day--weekend {
    opacity: 0.3;
}