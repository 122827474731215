.oac-paging-selection {
    display: inline-block;
    width: 60px;
}

.oac-paging-selection .esp-select {
    margin: auto;
}

.oac-paging-selection .esp-select__control {
    width: 57px;
}

.oac-paging-selection .esp-select__indicators {
    background: transparent;
    border: none !important;
    width: 22px;
    padding: 0 7px;
}

.oac-paging-selection .esp-select__dropdown-indicator {
    color: black !important;
    background-color: black !important;
    width: 0.5em !important;
    height: 0.4em !important;
    margin-top: 2px;
}

.oac-paging-selection .esp-select__placeholder {
    font-style: normal;
}

.oac-paging-selection .esp-select__menu {
    margin-top: 1px !important;
    width: 57px !important;
    margin-left: 0;
    min-width: 57px !important;
}

.oac-paging-selection .esp-select {
    width: 100px;
}

.oac-paging-selection .esp-select__menu-list .esp-select__option {
    width: 57px;
}