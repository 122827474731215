.change-language-modal {
    font-size: 20px;
    z-index: 99;
}

.change-language-modal .modal-dialog {
    margin: 15% 37%;
}

.change-language-modal .modal-dialog .modal-content {
    width: 485px;
}

.change-language-modal .modal-dialog .modal-content input {
    margin-top: 10px;
    padding-left: 10px;
    width: 400px;
    height: 35px;
}

.change-language-modal .modal-dialog .modal-content,
.change-language-modal .modal-dialog .modal-body {
    min-height: 150px;
}

.change-language-modal .modal-dialog .modal-footer {
    height: 55px;
    min-height: 55px;
    margin-top: 0;
    padding: 0;
}

.change-language-modal .iresp-dropdown {
    display: inline-block;
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
}

.change-language-modal .iresp-dropdown .select {
    width: 100%;
}

.change-language-modal input::placeholder {
    font-style: italic !important;
    font-size: 20px;
}

.change-language-modal .error {
    font-style: italic !important;
}