.esp-select {
    margin-top: -2px;
}

.esp-select .option-id {
    padding: 0 5px;
}

.esp-select .option-name {
    padding-left: 15px;
}

.esp-select__control {
    min-height: 27px !important;
    /*border-color: var(--main-color) !important;*/
    border-radius: 4px;
    box-shadow: none !important;
    height: 36px;
    border-color: #000 !important;
}

.esp-select__placeholder {
    /*margin-top: -2px;*/
    color: #B8B9B2 !important;
    font-style: italic;
}

.esp-select__indicators {
    /*margin-top: -5px;*/
    border-left: 1px solid black; background-color: #aaa;
    height: 34px;
    padding: 0 10px;
}

.esp-select__indicator-separator {
    display: none !important;
}

.esp-select__dropdown-indicator {
    /*background: var(--main-color) !important;*/
    padding: 3px 4px !important;
    color: white !important;
    border-radius: 0 3px 3px 0;
    content: "";
    width: 0.4em !important;
    height: 0.25em !important;
    background-color: #fff !important;
    clip-path: polygon(50% 79%, 0 0, 100% 0);
    margin-top: -1px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.esp-select__menu {
    margin-top: 1px !important;
    margin-left: 0;
    min-width: 450px !important;
}

.esp-select__menu-list {
    min-width: 450px !important;
}

.esp-select__menu-list .esp-select__option {
    border-bottom: 1px solid rgb(192, 192, 192);
    min-height: 35px;
    padding: 1px 10px;
}

.esp-select__menu-list .esp-select__option:last-child {
    border: none;
}