.sort-icon-wrapper {
    display: inline-block;
    width: 20px;
    position: absolute;
    bottom: 5px;
    right: 0;
}

.sort-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.sort-icon img {
    width: 15px;
}