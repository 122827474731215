.filter-wrapper {
    margin-bottom: 50px;
}

.filter-wrapper span.filter-placeholder {
    margin-left: 20px;
}

.filter-wrapper div:nth-child(2) .esp-select{
    width: 325px;
}

/*.filter-wrapper div:nth-child(3) .esp-select{*/
    /*width: 325px;*/
/*}*/

/*.filter-wrapper div:nth-child(4) .esp-select{*/
    /*width: 325px;*/
/*}*/

.filter-wrapper .esp-select {
    height: 35px;
    margin-left: 20px;
}

.filter-wrapper img {
    margin-left: 20px;
    margin-bottom: 8px;
    cursor: pointer;
}

.filter-wrapper .filter-border-wrapper {
    width: 485px;
    margin-left: 20px;
    border: 1px solid black;
    padding: 0 0 0 10px;
    position: relative;
}

.filter-wrapper .filter-border-wrapper .esp-select {
    margin-left: 70px;
}


.filter-wrapper .filter-border-wrapper .esp-select {
    width: 400px !important;
    display: inline-block;
    border: none !important;
    height: 20px !important;
}

.filter-wrapper .filter-border-wrapper .esp-select__value {
    height: 20px !important;
    width: 500px;
}

.filter-wrapper .filter-border-wrapper .esp-select__control {
    border: none !important;
    height: 20px;
    margin-top: 2px;
    margin-left: 50px;
}

.filter-wrapper .filter-border-wrapper .esp-select__indicators {
    background: transparent;
    border: none !important;
}

.filter-wrapper .filter-border-wrapper .esp-select__dropdown-indicator {
    color: black !important;
    background-color: black !important;
    width: 1em !important;
    height: 0.8em !important;
}

.filter-wrapper .filter-border-wrapper .cc-id {
    position: absolute;
    top: 4px;
}

.filter-wrapper .filter-border-wrapper .cc-id.grey {
    color: #c0c0c0;
    z-index: 2;
}

.filter-wrapper .filter-border-wrapper .esp-select__menu {
    margin-top: 17px !important;
    width: 450px !important;
}

.filter-wrapper .esp-select__indicators {
    background: transparent;
    border: none !important;
}

.filter-wrapper .esp-select__dropdown-indicator {
    color: black !important;
    background-color: black !important;
    width: 1em !important;
    height: 0.8em !important;
    margin-top: 2px;
}

.filter-border-wrapper .esp-select__single-value {
    margin-left: 25px;
}