#login-container {
    font-size: 20px;
}

#login-form {
    width: 405px;
    height: 400px;
    border: 2px solid grey;
    margin: 10% auto 0 auto;
    font-size: 20px;
    background-color: transparent;
}

#login-form.h475 {
    height: 405px;
}

#login-form .login-header {
    height: 65px;
    text-align: left;
}

#login-form .login-header .logo1 {
    font-size: 20px;
    color: rgb(161, 161, 161);
    font-weight: bold;
    display: inline-block;
    width: 54px;
    margin-left: 35px;
    margin-top: 6px;
}

#login-form .login-header .logo2 {
    margin: 20px 40px 22px 40px;
    display: inline-block;
}

#login-form .login-title {
    height: 50px;
    color: white;
    background-color: rgb(170, 170, 170);
    font-size: 20px;
    font-weight: bold;
}

#login-form .login-title span {
    display: inline-block;
    margin: 8px 0 0 40px;
}

#login-form .login-body {
    margin: 0 20px 0 40px;
    font-size: 20px;
    line-height: 1.5;
    color: black;
}

#login-form .login-body div {
    padding: 5px 0;
}

#login-form .login-body > div:nth-child(2) {
    padding-left: 0;
}

#login-form .login-body div input::placeholder {
    font-style: italic !important;
}

#login-form .login-body div input {
    width: 310px;
    height: 35px;
    margin: 5px 0;
    /* padding: 10px; */
    color: black;
    border: 1px solid #aaa;
    padding: 0 5px;
    border-radius: 5px;
}

#login-form .login-body div.flag-select {
    width: 300px;
    height: 29px;
    background: #fff;
    border: 0.5px solid #A9A9A9;
    margin-left: 5px;
    margin-right: 5px;
}

#login-form .login-body button.flag-select__btn {
    font-size: 12px !important;
    margin-top: -10px;
}

#login-form .flag-select__option--placeholder {
    padding: 0 8px 3px 0px;
}

#login-form .flag-select__options {
    margin-top: 0;
}

#login-form .login-body img.flag-select__option__icon {
    top: -1px;
}

#login-form .flag-select__option--placeholder {
    width: 210px !important;
    color: #B8B9B2;
    font-style: italic;
    text-align: left;
}

#login-form .flag-select__option__label {
    color: black;
}

#login-form .flag-select__option__label {
    font-style: normal;
}

#login-form .login-footer {
    margin: 5px 0 0 32px;
}

#login-form .login-footer .forget {
    /* display: none; */
    color: #0080ff;
    cursor: pointer;
}

#login-form .login-footer button {
    /*text-align: center;*/
    /*margin-right: 45px;*/
    width: 160px;
    height: 28px;
    color: #0080ff;
    border: 0;
    background-color: transparent;
    margin-top: 15px;
    font-size: 20px;
    text-decoration: underline;
}

#login-form .login-footer button:nth-child(2) {
    margin-left: 75px;
    width: 100px;
}

#login-form.ja .login-footer button:nth-child(2) {
    margin-left: 50px;
    width: 112px;
}

#login-form .login-footer button:focus, button::selection {
    border-radius: 5px;
    /*border: 1px solid #43CABD;*/
}

.login-error {
    color: red;
    margin: 0 auto;
    font-style: italic;
    width: 405px;
    border: 1px dashed red;
    padding: 3px 0 5px 5px;
    font-size: 20px;
}

#login-container .iresp-modal .modal-dialog {
    margin: 15% 40%;
}

@media only screen and (max-width: 1366px) {
    #login-container .iresp-modal .modal-dialog {
        margin: 15% 35%;
    }
}

#login-container .iresp-modal .modal-dialog .modal-content {
    min-height: 200px;
}

#login-container .iresp-modal .modal-dialog .modal-body {
    min-height: 85px;
}

#login-container .iresp-modal .modal-dialog .modal-footer {
    min-height: 60px;
    padding: 0;
}

#login-container .iresp-modal .modal-dialog .modal-footer button {
    min-width: 0;
}

#login-container .logo-text {
    font-size: 20px;
    /*color: #929292;*/
    color: #606060;
    letter-spacing: 1px;
    margin: 18px auto;
    display: inline-block;
    font-weight: bold;
    font-family: var(--main-font), sans-serif;
}

#login-container .esp-select {
    width: 310px;
    height: 28px;
    color: black;
}

#login-container .esp-select__placeholder {
    top: 24%;
    left: 1%;
}

#login-container .esp-select__single-value {
    left: 10px;
    top: 20%;
}

#login-container .esp-select__indicators {
    margin-top: -5px;
    padding: 0 6px !important;
    height: 32px;
}

#login-container .esp-select__control {
    height: 34px;
    min-height: 30px;
}

#login-container .esp-select__menu {
    margin-top: 10px !important;
    padding: 0 0 0 0 !important;
}

#login-container .esp-select__menu-list {
    padding: 0 0 0 0 !important;
}

#login-container .esp-select__menu-list .esp-select__option {
    border-bottom: 1px solid rgb(192, 192, 192);
    height: 32px;
    padding: 1px 10px;
}

#login-container .esp-select__menu-list .esp-select__option:last-child {
    border: none;
}

#login-container .group-label {
    margin-top: 20px;
    margin-bottom: -10px;
}

#login-container .proceed {
    display: inline-block;
    width: 100% !important;
    text-align: right;
    padding-right: 47px;
    margin-top: 60px !important;
}

#login-container .proceed.done {
    margin-top: 10px !important;
}

#login-container .proceed.final {
    margin-top: 30px !important;
}

#login-container .reset {
    margin-top: -60px !important;
}

#login-container .proceed.disabled {
    color: grey;
    opacity: 0.7;
}

#login-container .verification-code {
    width: 40px !important;
    height: 47px !important;
    margin: 30px 15px 50px 0 !important;
    text-align: center;
    border-radius: 0 !important;
    border: 2px solid #999 !important;
}

#login-container .oac-password img {
    right: 40px;
    top: 12px;
}

#login-container .password-cond {
    width: 27px;
    margin-right: 15px;
}

#login-container .div-cond {
    padding: 0 !important;
    line-height: 1.6;
}

#login-container .cond-ok {
    color: #7EC202;
    font-style: italic;
}

#login-container .cond-nok {
    color: #F00001;
    font-style: italic;
}

#login-container .oac-password {
    padding: 0 !important;
}

#login-container .mt10 {
    margin-top: 10px !important;
}

#login-container .mt40 {
    margin-top: 40px !important;
}

#login-container .pt5 {
    padding: 5px 0 0 0 !important;
}

#login-container .pb5 {
    padding: 0 0 5px 0 !important;
}

#login-container .pt15 {
    padding: 15px 0 0 0 !important;
}

#login-container .pt10 {
    padding: 10px 0 0 0 !important;
}