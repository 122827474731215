.report-wrapper .discharge-modal .modal-content {
    width: 420px;
    box-shadow: 5px 5px 10px #888888;
}

.report-wrapper .discharge-modal .modal-content .modal-header {
    height: 55px;
}

.report-wrapper .discharge-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
}

.report-wrapper .discharge-modal .modal-content .mt10 {
    margin-top: 10px;
}

.report-wrapper .discharge-modal .modal-content .mb10 {
    margin-bottom: 10px;
}

.report-wrapper .discharge-modal .modal-content .red {
    color: #E61610;
    font-weight: bold;
}

.report-wrapper .discharge-modal .modal-content .m35 {
    margin-top: 25px;
}

.report-wrapper .discharge-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.report-wrapper .discharge-reconfirm-modal .modal-content {
    width: 420px;
    min-height: 50px;
    top: 255px;
}

.report-wrapper .discharge-reconfirm-modal .modal-content .modal-header {
    height: 55px;
}

.report-wrapper .discharge-reconfirm-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
    min-height: 50px;
}

.report-wrapper .discharge-reconfirm-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.report-wrapper .transfer-request-modal .modal-content {
    width: 420px;
}

.report-wrapper .transfer-request-modal .modal-content .m35 {
    margin-top: 25px;
}

.report-wrapper .transfer-request-modal .modal-content .modal-header {
    height: 55px;
}

.report-wrapper .transfer-request-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
    min-height: 320px;
}

.report-wrapper .transfer-request-modal .modal-content .modal-body .dest{
    margin-top: 30px;
}

.report-wrapper .transfer-request-modal .modal-content .modal-body .select-dest{
    margin-top: 30px;
}

.report-wrapper .transfer-request-modal .iresp-dropdown {
    margin-left: 0;
}

.report-wrapper .transfer-request-modal .iresp-dropdown .select {
    width: 320px;
    font-size: 16px;
    font-weight: normal;
}

.report-wrapper .transfer-request-modal .iresp-dropdown select {
    font-size: 16px;
    font-weight: normal;
}

.report-wrapper .transfer-request-modal .iresp-dropdown .select .selected{
    font-size: 16px;
    font-weight: normal;
}

.report-wrapper .transfer-request-modal input[type=checkbox] {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-top: 2px;
}

.report-wrapper .transfer-request-modal div.inline {
    width: 50%;
}

.report-wrapper .transfer-request-modal .inline span {
    margin-left: 30px;
}

.report-wrapper .transfer-request-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}