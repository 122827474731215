#mail-btn {
    position: relative;
}

.mail-cnt {
    background-color: red;
    color: white;
    border-radius: 5px;
    font-size: 12px !important;
    padding: 0 8px;
    position: absolute;
    top: -6px;
    right: -5px;
}