.oac-search-field {
    position: relative;
    float: right;
}

.oac-search-field img {
    width: 20px !important;
    position: absolute;
    top: 5px;
    right: 7px;
}

.oac-search-field input {
    padding: 0 10px;
}